import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import lodash from 'lodash';
import { saveAs } from 'file-saver';
import UploadComp from "./Upload"
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress,
  Divider,
  Modal
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {DataTable,DataToolbar} from 'components';
import CityList from 'assets/json/tinh.json';
import {ProductList} from 'views/Admin';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
  ,modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  form:{
    flexBasis: 500,
  },
  note: {
    whiteSpace: 'pre-wrap'
  }
}));

const Edit = props => {
  const {item,onCancel,onSuccess,cats} = props;
  const history  = useHistory();
  const confirm = CommonHelper.useConfirm();
  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
const [items, setItems] = React.useState([]);
const [eitems, setEItems] = React.useState([]);
const dataValues = [
  {label:'Name',value:'name',className:classes.link,action:'download'},
  {label:'Size',value:'size'},
  {label:'Type',value:'type'},
  {label:'Created',width:150,value:'created',align:'right',dateFormat:'DD/MM/YYYY HH:mm:ss'}];
  const getStatus = (item) =>{
      return (<div><span style={{color:item.status==1?"green":"red"}}>{item.status==1?"Approved":"Not Approved"}</span>

    </div>)
    }
  const dataValues2 = [
    {label:'Name',value:'name',className:classes.link,action:'download2'},
    {label:'Size',value:'size'},
    {label:'Status',value:'status',type:"custom",renderItem:getStatus},
    {label:'Feedback',value:'feedback',className:classes.note},
    {label:'Created',width:150,value:'created',align:'right',dateFormat:'DD/MM/YYYY HH:mm:ss'}];
const dataActions = [{name:'Download',type:'download'},{name:'Delete',type:'delete',color:'red'}];
const dataActions2 = [{name:'Download',type:'download2'},{name:'Delete',type:'delete2',color:'red'}];
const toolbarActions = [];
React.useEffect(()=>{
  loadItems();
  loadEItems();
  //loadCounter();
},[])
const loadItems = async () =>{
  let data = await AdminHelper.UserApi.GetAllFiles(item.id,{});
  setItems(data);
}
const loadEItems = async () =>{
  let data = await AdminHelper.UserApi.GetAllEFiles(item.id,{});
  setEItems(data);
}
const loadNextItems = async (after) => {
  console.log(after);
  let rel = await AdminHelper.UserApi.GetAllFiles(item.id,{after});
  if(rel)
  {
    let arr = items.concat(rel);
    setItems(arr);
  }
}
const handleActions = (type,item) => {

  if(type=='download')
  {
    var ext =  item.uri.split('.').pop();
  //  console.log(item.name);
    saveAs(CommonHelper.VIDEO_PATH+item.user+"/"+item.uri,item.name+"."+ext);
    // var win = window.open(CommonHelper.VIDEO_PATH+item.user+"/"+item.uri, '_blank');
    //   win.focus();
  }
  if(type=='download2')
  {
    var ext =  item.uri.split('.').pop();
  //  console.log(item.name);
    saveAs(CommonHelper.VIDEO_PATH+item.user+"/"+item.uri,item.name+"."+ext);
    // var win = window.open(CommonHelper.VIDEO_PATH+item.user+"/"+item.uri, '_blank');
    //   win.focus();
  }
  if(type=='delete')
  {
    confirm({ title:'Do you want to delete this file?',description: 'You cant recovery it',confirmText:'Delete now',cancelText:'Cancel' })
        .then( () => {
          deleteItem(item);
          //console.log('ok');
        }).catch(()=>{
          //console.log('cancel');
        });
  }
  if(type=='delete2')
  {
    confirm({ title:'Do you want to delete this file?',description: 'You cant recovery it',confirmText:'Delete now',cancelText:'Cancel' })
        .then( () => {
          deleteItem2(item);
          //console.log('ok');
        }).catch(()=>{
          //console.log('cancel');
        });
  }

}
const deleteItem = async (i) =>{
    let rel = CommonHelper.postFormData(CommonHelper.DELETE_PATH,{file:"videos/"+i.user+"/"+i.uri});
    let data = await AdminHelper.UserApi.DeleteFile(i.user,i.id);
    loadItems()
}
const deleteItem2 = async (i) =>{
    let rel = CommonHelper.postFormData(CommonHelper.DELETE_PATH,{file:"videos/"+i.user+"/"+i.uri});
    let data = await AdminHelper.UserApi.DeleteEditedFile(i.user,i.id);
    loadEItems()
}
const deleteAllItem = async (i) =>{
  await setLoading(true);
    let rel = CommonHelper.postFormData(CommonHelper.DELETEFOLDER_PATH,{folder:"videos/"+item.user});
  //  let rel = CommonHelper.postFormData(CommonHelper.DELETE_PATH,{file:"videos/"+i.user+"/"+i.uri});
    let data = await AdminHelper.UserApi.DeleteAllFiles(item.id);
    loadItems();
    await setLoading(false);
}
const clearFiles = async() => {
    confirm({ title:'Do you want to delete all files?',description: 'You cant recovery them',confirmText:'Delete All now',cancelText:'Cancel' })
        .then( () => {
          deleteAllItem(item);
          //console.log('ok');
        }).catch(()=>{
          //console.log('cancel');
        });
}
const addNewClick = () => {
  setShowPicker(item);
}
const reloadItems = () => {

loadEItems();
setShowPicker(false);
}

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={()=>onCancel()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div style={{width:'100%',maxWidth:'800px'}}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                  align="center"
                >
                  {item.name}
                </Typography>
                <Typography
                  className={classes.title}
                  variant="h4"
                >
                  total: {item.files||0}
                </Typography>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={clearFiles}
                >
                  {loading?<CircularProgress color="secondary" />:"Clear all files"}
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
        >
        <DataTable key={'2'} handleActions={handleActions} loadNextItems={loadNextItems} mobileLayout={true} dataActions={dataActions} dataValues={dataValues} data={items} rows={10} total={items.length<10?items.length:-1} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{marginTop:20,marginBottom:5}}
      >
      <Divider />
      <Typography
        className={classes.title}
        variant="h2"
        align="center"
      >
        Edited Videos
      </Typography>
      <Button
        className={classes.signInButton}
        color="primary"
        size="large"
        variant="contained"
        onClick={addNewClick}
      >
        {loading?<CircularProgress color="secondary" />:"Add New"}
      </Button>

      <DataTable key={'3'} handleActions={handleActions} loadNextItems={loadNextItems} mobileLayout={true} dataActions={dataActions2} dataValues={dataValues2} data={eitems} rows={10} total={eitems.length<10?eitems.length:-1} />
    </Grid>
      </Grid>
      <Modal
        open={showPicker!=false}
        onClose={()=>{setShowPicker(false)}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {showPicker&&<UploadComp onSuccess={reloadItems} item={item} onCancel={()=>setShowPicker(false)} />}

      </Modal>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object
};

export default Edit;
