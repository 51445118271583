import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,ButtonBase } from '@material-ui/core';
import {AdminHelper} from 'helpers';
import moment from 'moment';
import _ from 'lodash';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import {
  TotalCard,
  LastReport
} from './components';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import CategoryIcon from '@material-ui/icons/Category';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    width:'100%'
  }
}));

const Dashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();
  const [counter, setCounter] = React.useState(null);
  const [chartData, setChartData] = React.useState(null);
  React.useEffect(()=>{
   // loadCounter();
   // loadCounterByDate();
  },[]);
  const loadCounter = async () =>{
    let data = await AdminHelper.ReportApi.Counter();
    setCounter(data);
  }
  const loadCounterByDate = async () =>{
    let start =  moment().subtract(6,'days').startOf('day').format("YYYYMMDD");
    let end =  moment().endOf('day').format("YYYYMMDD");
    let data = await AdminHelper.ReportApi.CounterByDate(start,end,'Day');
    setChartData(data);
  }
  if(!AdminHelper.checkAdminAccount())
  {

    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }
  const dataByDay = () => {
    let data = {labels:[],data1:[],data2:[]};

    if(chartData)
    for(let i=6;i>=0;i--)
    {
      let item =  moment().subtract(i, "days").format("DD/MM");
      data.labels.push(item);
      let day =  moment().subtract(i, "days").format("YYYYMMDD");
      let obj = _.find(chartData, {'id':day});
      data.data1.push(obj?obj.User:0);
      data.data2.push(obj?obj.Product:0);
    }
    console.log(chartData);

    return data;


  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
      <Grid
        item
        lg={4}
        md={4}
        xs={12}
      >
        {counter?<ButtonBase className={classes.button} onClick={()=>history.push('/admin/category')}><TotalCard Icon={CategoryIcon} color={theme.palette.error.main} title="DANH MỤC" total={counter.Category} />
    </ButtonBase>:false}
      </Grid>
        <Grid
          item
          lg={4}
          md={4}

          xs={12}
        >
          {counter?<ButtonBase className={classes.button} onClick={()=>history.push('/admin/users')}><TotalCard Icon={PeopleIcon} title="KHÁCH HÀNG" total={counter.User} />
      </ButtonBase>:false}
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
        >
          {counter?<ButtonBase className={classes.button} onClick={()=>history.push('/admin/products')}><TotalCard Icon={MotorcycleIcon} color={theme.palette.primary.main} title="SẢN PHẨM" total={counter.Product} />
      </ButtonBase>:false}
        </Grid>
        {chartData?<Grid
          item
          xs={12}
        >
          <LastReport chartData={dataByDay()} />
        </Grid>:false}
      </Grid>
    </div>
  );
};

export default Dashboard;
