import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button,Typography } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  row2: {
    minHeight: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const DataToolbar = props => {
  const { className,title,onSearchChange,onActionCallback,actions,customFilter,enableSearch, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    <div className={classes.row}>
      <Typography
        className={classes.title}
        variant="h2"
      >
        {title}
      </Typography>
    </div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {actions.map((action)=>(
          <Button
            key={action.type}
            color="primary"
            variant="contained"
            onClick={()=>onActionCallback(action.type)}
          >
            {action.name}
          </Button>
        ))}
      </div>
      {enableSearch||customFilter?<div className={classes.row2}>
        {enableSearch&&<SearchInput
          className={classes.searchInput}
          placeholder={"Search"}
          onChange={onSearchChange}
        />}
      {customFilter&&customFilter()}
    </div>:false}
    </div>
  );
};

DataToolbar.propTypes = {
  className: PropTypes.string,
  ustomFilter:PropTypes.func,
};

export default DataToolbar;
