import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,Container,Typography,IconButton,Badge,Hidden,useMediaQuery } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import { SearchTopInput } from 'components';
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor:theme.palette.primary.main,
    padding:theme.spacing(2)
  },
  logo:{
    width:180,
    display:'flex',
    flexDirection:'row',
    alignItems:"center"
  },
  nav_right:{
    width:180,
    display:'flex',
    flexDirection:'row',
    alignItems:"center",
    justifyContent:'flex-end'
  },
  logoText:{
    color:'white',
    marginLeft:theme.spacing(1),
    marginRight:theme.spacing(1)
  },
  iconbutton:{
    padding:theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" >
        <Grid container direction="row">
          <Grid item {...downSM?{xs:true}:false} className={classes.logo}>
            <RouterLink to="/" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img
                alt="Logo"
                src="/images/2u_logo.png"
                height="36"
              />
            <Typography variant="h2" className={classes.logoText}>2U Store</Typography>
            </RouterLink>

          </Grid>
          <Hidden smDown>
          <Grid item sm>
            <SearchTopInput placeholder="Search Store" />
          </Grid>
        </Hidden>
          <Grid item className={classes.nav_right}>
            <IconButton aria-label="cart" className={classes.iconbutton}>
              <Badge badgeContent={4} color="secondary">
                <ShoppingCartIcon style={{color:'white'}} fontSize="large" />
              </Badge>
            </IconButton>
            <IconButton aria-label="user" className={classes.iconbutton}>
                <PersonIcon style={{color:'white'}} fontSize="large" />
                <Typography variant="h4" className={classes.logoText}>Sign In</Typography>
            </IconButton>

          </Grid>

          {downSM?<Grid item xs={12}>
            <SearchTopInput placeholder="Search Store" />
          </Grid>:false}


        </Grid>

      </Container>
    </div>



  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
