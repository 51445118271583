import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm,UploadFile} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import moment from "moment";
import _ from 'lodash';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles(theme => ({
  root: {
    width:'100%',maxWidth:'800px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  grid: {
    height: '100%'
  },

  signInButton: {
    margin: theme.spacing(2, 0)
  },content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
  ,
  form:{
    flexBasis: 500,
  }
}));

const Edit = props => {
  const {item,onCancel,onSuccess} = props;
  const history  = useHistory();

  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);
const [Files,setFiles] = React.useState([]);
  useEffect(() => {

  }, []);


  const confirmClick = event => {
      event.preventDefault();
      updateData();
  }

  const updateData = async () =>{
    await setLoading(true);
    if(Files.length>0)
    {
      let file = Files[0];
      let size = Math.ceil(file.size/(1000*1000));
      let name = moment().format("YYYYMMDD_HHmmss");
      console.log(size,name);
      let newItem = {name,id:name,uri:"",size,created:moment().valueOf(),user:item.id,sync:2};
      let rel = await CommonHelper.postFormData2(CommonHelper.API_UPLOAD,{folder:item.id,media:file});
      if(rel&&rel.status==1)
      {
        newItem.uri=rel.data;
        let data = await AdminHelper.UserApi.addEditedFile(item.id,newItem);
        onSuccess();
      }
      else {
        CommonHelper.NotificationManager.error('Upload Failed');

      }

      //let rel = await postFormData2(API_UPLOAD,{folder:item.user,media:{name:item.name+"."+(item.uri.split('.').pop()),type:file.type,uri:item.uri}});
    }
  //  console.log(Files[0]);
    await setLoading(false);
  }

  const onFileChanged = (files) => {
    setFiles(files);
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={()=>onCancel()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div style={{width:'100%',maxWidth:'800px'}}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Add Edited Video
                </Typography>
                <UploadFile onChange={onFileChanged} accept="video/*" limit={1} />

                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!Files||Files.length==0}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={confirmClick}
                  >
                    {loading?<CircularProgress color="secondary" />:"Add"}
                  </Button>
              </div>
            </div>
          </div>
        </Grid>

      </Grid>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object
};

export default Edit;
