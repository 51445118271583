import * as CommonHelper from 'helpers/Common';
import _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
!firebase.apps.length?firebase.initializeApp(CommonHelper.FIREBASE):firebase.firestore();
const collection = "Documents";
const firestore = firebase.firestore();
const GETALL_API = CommonHelper.ADMIN_SERVER+'User-GetAll';
const CREATE_FOLDER = CommonHelper.ADMIN_SERVER+'create_folder';
const UPDATE_API = CommonHelper.ADMIN_SERVER+'User-Update';
const DELETE_API = CommonHelper.ADMIN_SERVER+'User-Delete';
const GETBYID_API = CommonHelper.ADMIN_SERVER+'User-GetById';
const CHECKID_API = CommonHelper.ADMIN_SERVER+'User-CheckID';
const SEARCH_API = CommonHelper.ADMIN_SERVER+'User-Search';
export async function GetAll(params={}){

      let docRef = firestore.collection(collection).orderBy("created","desc").limit(10);
        if(params.after)
        {
          let doc = await firestore.collection(collection).doc(params.after).get();
          docRef = docRef.startAfter(doc);
        }

          let data = await docRef.get();

        let results = [];
        if(data.size>0)
        {
          data.forEach((doc)=>{
            let item = doc.data();
            item.id = doc.id;
            results.push(item);
          })
        }

    return results;
}


export async function addFile(file,name) {
  // add file to storage bucket
  let result = false
  try{
    const storageRef = firebase.storage().ref("documents");
    const fileRef = storageRef.child(name);
    await fileRef.put(file);
    result = true;
  }
  catch(e){
    console.log(e);
  }
  
  return result;
}
// Get download url from storage bucket
export async function getDownloadUrl(name) {
  let result = false;
  try{
    const storageRef = firebase.storage().ref("documents");
    const fileRef = storageRef.child(name);
    result = await fileRef.getDownloadURL();
  }
  catch(e){
    console.log(e); 
  }
  return result;
}
export async function getFileDownloadUrl(name) {
  let result = false;
  try{
    const storageRef = firebase.storage().ref("files");
    const fileRef = storageRef.child(name);
    result = await fileRef.getDownloadURL();
  }
  catch(e){
    console.log(e); 
  }
  return result;
}

export async function Add(data){
  let relData = 0;
  const params = data;
  console.log(params);
  if (params.name && params.uri&&params.id) {
    let docRef = firestore.collection(collection).doc(params.id);
    let search = generateSearch(params);

    await docRef.set({...params,search,created:(new Date().getTime()),updated:(new Date().getTime())});
    relData = params.id;
  }
  // if(relData!=0)
  // {
  //   let result = await CommonHelper.postFormData(CREATE_FOLDER,{folder:relData});
  //   console.log(result);
  // }
    return relData;
}
export async function Update(data){
  let relData = 0;
  const params = data;
  if (params.id) {
    let doc = await firestore.collection(collection).doc(params.id).get();
    if(doc.exists)
    {
      let newDoc = _.cloneDeep(params);
      delete newDoc['created'];
      let search = generateSearch(newDoc);
      let docRef = firestore.collection(collection).doc(params.id);
      await docRef.update({...newDoc,search,updated:(new Date().getTime())});
      relData = docRef.id;
    }
  }
  // if(relData!=0)
  // {
  //   let result = await CommonHelper.postFormData(CREATE_FOLDER,{folder:relData});
  //   console.log(result);
  // }
  return relData;
}

export async function updateFile(file,name) {
  // update file to storage bucket
  let result = false
  try{
    const storageRef = firebase.storage().ref("files");
    const fileRef = storageRef.child(name);
    await fileRef.put(file);
    result = true;
  }
  catch(e){
    console.log(e);
  }
}
export async function Delete(id){
  //delete from storage bucket
  let result = false;
  try{
    const storageRef = firebase.storage().ref("documents");
    const fileRef = storageRef.child(id);
    await fileRef.delete();
    result = true;
  }
  catch(e){
    console.log(e);
  }

  let doc = await firestore.collection(collection).doc(id).get();
    if(doc.exists)
    {
      await firestore.collection(collection).doc(id).delete();
      return 1;
    }

    return 0;
}
export async function GetById(id){
    let result = await CommonHelper.postFormData(GETBYID_API,{id});
    let rel = null;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}

export async function Search(search){
    let result = await CommonHelper.postFormData(SEARCH_API,{search});
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}
function generateSearch(item){
  let arr = CommonHelper.slugify(item.name).split('-');
  arr.push(CommonHelper.slugify(item.name).replace(/\-/gi, " "));
  return arr;
}
