import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Input,IconButton } from '@material-ui/core';
import Dropzone from "react-dropzone";
import Resizer from 'react-image-file-resizer';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
  dropzone: {
    display: 'flex',position:'relative', justifyContent: 'center', alignItems: 'center',width: '120px',height: '120px',borderWidth: '2px',borderColor: 'rgb(102, 102, 102)',borderStyle: 'solid',borderRadius: '5px'
  },
  delete:{
    position:'absolute',right:0,top:0,color:'white',backgroundColor:'red'
  }

}));


const FileUpload = props => {
  const { className, Items,onChange,replace,style,accept,multiple,limit,size, ...rest } = props;

  const classes = useStyles();

  const [Files,setFiles] = React.useState([]);
  const onDrop = (files,type,index=0) => {
  //   files.map(file => Object.assign(file, {
  //   preview: URL.createObjectURL(file)
  // }));
  let arr = _.cloneDeep(Files);
  if(type=="replace")
  {
    let file = files[0];

    arr[index] = file;
  }
  else {
    for(let file of files)
    {
      arr.push(file)
    }

  }
  setFiles(arr);
  onChange(arr);

  }
  const deleteItem = (index) => {
      let Arr = _.cloneDeep(Files);
      Arr.splice(index, 1);
      setFiles(Arr);
      onChange(Arr);
  }

  return (
    <Grid
      container
      direction='row'
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
    {Files.map((item,index)=>
      {
        return (
      <Grid key={index} item xs={6} sm={4} >
        <div style={{position:'relative',width:120,height:120}}>
        <Dropzone onDrop={files=>onDrop(files,'replace',index)} accept={accept?accept:"image/*"} multiple={multiple?multiple:false}  noClick={replace==true?false:true} >
        {({getRootProps, getInputProps}) => {
          return (
            <div {...getRootProps()} className={classes.dropzone} style={{borderColor:'green'}} >
              <input {...getInputProps()} />
              {item.name}
            </div>
          )
        }}
      </Dropzone>
      <IconButton size='small' className={classes.delete} style={{backgroundColor:'green'}} onClick={()=>deleteItem(index)}>
      <CloseIcon />
      </IconButton>
    </div>
      </Grid>
    )})}

    {!limit||limit==0||limit>(Files.length)?<Grid item xs={6} sm={4} >
      <div style={{position:'relative',width:120,height:120}}>

      <Dropzone onDrop={files=>onDrop(files,'new')} accept={accept?accept:"image/*"} multiple={multiple?multiple:false}   >
      {({getRootProps, getInputProps}) => {
        return (
          <div {...getRootProps()} className={classes.dropzone} >
            <input {...getInputProps()} />
          <AddIcon style={{ fontSize: 60 }}/>
          </div>
        )
      }}
    </Dropzone>

  </div>

    </Grid>:false}


    </Grid>
  );
};

FileUpload.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default FileUpload;
