import * as CommonHelper from 'helpers/Common';
import moment from "moment";
import * as firebase from 'firebase/app';
import 'firebase/firestore';
!firebase.apps.length?firebase.initializeApp(CommonHelper.FIREBASE):firebase.firestore();
const firestore = firebase.firestore();
const LOGIN_API = CommonHelper.ADMIN_SERVER + 'Admin-Login';
const CHECKTOKEN_API = CommonHelper.ADMIN_SERVER + 'Admin-CheckToken';
const UPDATE_API = CommonHelper.ADMIN_SERVER + 'Admin-Update';

export async function Login(email, password) {

  let relData = null;
  let data = await firestore.collection('Admin').where('email', "==", email.toLowerCase()).get();
  if (data.size > 0) {

    let user = {};
    data.forEach(doc => {
      user = doc.data();
      user.id = doc.id;
    })
    console.log(user);
    if (user.password == password) {
      let tokenDoc = firestore.collection("Tokens").doc();
      let token = { id_user: user.id, expired: moment().add(10, "days").valueOf(), type: 'admin' };
      await tokenDoc.set(token);
      token.id = tokenDoc.id;
      relData = { user: { id: user.id, name: user.name, type: user.type }, token }
    }
  }

  return relData;
}
export async function CheckToken(user, token) {
  let relData = null;
  let doc = await firestore.collection('Tokens').doc(token.id).get();
  if(doc.exists>0)
  {
    let tokenDoc = doc.data();
    tokenDoc.id = doc.id;
    if(tokenDoc.id_user == user.id && moment(tokenDoc.expired).isAfter(moment()))
    {
      relData = {
          status:1,
          message: "",
          data:{token}
        }
    }
  }

  return relData;
}
export async function Update(data) {
  let relData = null;
  const params = data;
  if (params.id&&params.password)
  {
    let doc = await firestore.collection('Admin').doc(params.id).get();
    if(doc.exists)
    {
        let user = doc.data();
        user.id = doc.id;
        if(user.password == params.password)
        {
          let newPass = params.new_password?{password:params.new_password}:{};
          await firestore.collection('Admin').doc(params.id).update({name:params.name, ...newPass});
      relData = {id:user.id,name:params.name,type:user.type};
        }
      }
    }

  return relData;
}
