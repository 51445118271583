import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Input,IconButton } from '@material-ui/core';
import Dropzone from "react-dropzone";
import Resizer from 'react-image-file-resizer';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
  dropzone: {
    display: 'flex',position:'relative', justifyContent: 'center', alignItems: 'center',width: '120px',height: '120px',borderWidth: '2px',borderColor: 'rgb(102, 102, 102)',borderStyle: 'solid',borderRadius: '5px'
  },
  delete:{
    position:'absolute',right:0,top:0,color:'white',backgroundColor:'red'
  }

}));


const ImageUpload = props => {
  const { className, Items,onChange,style, ...rest } = props;

  const classes = useStyles();
  const [DeletedImages,setDeletedImages] = React.useState([]);
  const [NewImages,setNewImages] = React.useState([]);
  const [Images,setImages] = React.useState(Items?_.cloneDeep(Items):[]);
  const onDrop = (files,type) => {
  //   files.map(file => Object.assign(file, {
  //   preview: URL.createObjectURL(file)
  // }));
    let file = files[0];
    Resizer.imageFileResizer(
        file,
        600,
        600,
        'JPEG',
        100,
        0,
        uri => {
          let Arr = _.cloneDeep(NewImages);
            Arr.push({uri,name:file.name,type});
            setNewImages(Arr);
            onChange(Arr,DeletedImages);
        },
        'base64'
    );

  }
  const deleteItem = (index,type) => {
    if(type=='new')
    {
      let Arr = _.cloneDeep(NewImages);
      Arr.splice(index, 1);
      setNewImages(Arr);
      onChange(Arr,DeletedImages);
    }
    if(type=='old')
    {
      let DArr = _.cloneDeep(DeletedImages);
      DArr.push(Images[index]);
      let Arr = _.cloneDeep(Images);
      Arr.splice(index, 1);
      setImages(Arr);
      setDeletedImages(DArr);
      onChange(NewImages,DArr);
    }
  }
  return (
    <Grid
      container
      direction='row'
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
    {Images.map((item,index)=>(
      <Grid key={index} item xs={6} sm={4} >
        <div style={{position:'relative',width:120,height:120}}>
        <Dropzone accept="image/*" multiple={false}  noClick={true} >
        {({getRootProps, getInputProps}) => {
          return (
            <div {...getRootProps()} className={classes.dropzone} style={{borderColor:'green'}} >
              <input {...getInputProps()} />
            <img key={'image'} alt="" src={item} style={{maxWidth:'100%',maxHeight:'100%'}} />
            </div>
          )
        }}
      </Dropzone>
      <IconButton size='small' className={classes.delete} style={{backgroundColor:'green'}} onClick={()=>deleteItem(index,'old')}>
      <CloseIcon />
      </IconButton>
    </div>
      </Grid>
    ))}
    {NewImages.map((item,index)=>(
      <Grid key={index} item xs={6} sm={4} >
        <div style={{position:'relative',width:120,height:120}}>
        <Dropzone accept="image/*" multiple={false} noClick={true}   >
        {({getRootProps, getInputProps}) => {
          return (
            <div {...getRootProps()} className={classes.dropzone} style={{borderColor:'red'}} >
              <input {...getInputProps()} />
            <img key={'image'} alt="" src={item.uri} style={{maxWidth:'100%',maxHeight:'100%'}} />
            </div>
          )
        }}
      </Dropzone>
      <IconButton size='small' className={classes.delete} onClick={()=>deleteItem(index,'new')}>
      <CloseIcon />
      </IconButton>
    </div>
      </Grid>
    ))}
    <Grid item xs={6} sm={4} >
      <div style={{position:'relative',width:120,height:120}}>

      <Dropzone onDrop={files=>onDrop(files,'new')} accept="image/*" multiple={false}   >
      {({getRootProps, getInputProps}) => {
        return (
          <div {...getRootProps()} className={classes.dropzone} >
            <input {...getInputProps()} />
          <ImageIcon style={{ fontSize: 60 }}/>
          </div>
        )
      }}
    </Dropzone>

  </div>

    </Grid>


    </Grid>
  );
};

ImageUpload.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default ImageUpload;
