import { createStore } from 'redux';


const reducer = (state, action) => {

    if (action.type === "ACCOUNT") {
        return {
            ...state,
            ACCOUNT: action.ACCOUNT
        }
    }
    if (action.type === "ADMINACCOUNT") {
        return {
            ...state,
            ADMINACCOUNT: action.ADMINACCOUNT
        }
    }
    if (action.type === "LOADING") {
        return {
            ...state,
            LOADING: action.LOADING
        }
    }
    if (action.type === "LANG") {
        return {
            ...state,
            LANG: action.LANG
        }
    }
    if (action.type === "ADMINPATH") {
        return {
            ...state,
            ADMINPATH: action.ADMINPATH
        }
    }
    return state;
}

export default createStore(reducer, {ADMINACCOUNT:false,LANG:'en',ADMINPATH:'/admin',LOADING:false });
