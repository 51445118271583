import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,Typography,Modal } from '@material-ui/core';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import {DataTable,DataToolbar} from 'components';
import {EditComp,FileComp} from './components';
import _ from 'lodash';





const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  },
  note: {
    whiteSpace: 'pre-wrap'
  }
  ,modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },paper: {
      width:'100%',maxWidth:'800px',
      maxHeight:'100%',overflow:'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));
const defaultItem = {
  id:0,
  name:"",
  truckNumber:"",
  password:"",
  note:""
}

const UserList = () => {
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme();
  const confirm = CommonHelper.useConfirm();
  const [search, setSearch] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [showEdit, setShowEdit] = React.useState(null);
  const [showFiles, setShowFiles] = React.useState(null);
  const [interval, setInterval] = React.useState(null);
  const [searchData, setSearchData] = React.useState([]);
  const [showSearch, setShowSearch] = React.useState(false);
  const [counter, setCounter] = React.useState(null);
  const dataValues = [
    {label:'Name',value:'name',className:classes.link,action:'edit'},
    {label:'Password',value:'password'},
    {label:'Note',value:'note',className:classes.note},
    {label:'Created',width:150,value:'created',align:'right',dateFormat:'DD/MM/YYYY HH:mm:ss'}];
  const dataActions = [{name:'Edit',type:'edit'},{name:'Delete',type:'delete',color:'red'}];
  const toolbarActions = [{name:'Add',type:'add'}];



  React.useEffect(()=>{
    loadItems();
    //loadCounter();
  },[])
  const loadCounter = async () =>{
    let data = await AdminHelper.ReportApi.Counter();
    setCounter(data);
  }
  const loadItems = async () =>{
    let data = await AdminHelper.UserApi.GetAll();
    setItems(data);
  }
  const loadNextItems = async (after) => {
    let rel = await AdminHelper.UserApi.GetAll({after});
    if(rel)
    {
      let arr = items.concat(rel);
      setItems(arr);
    }
  }
  const loadSearch = async (text) =>{
    let data = await AdminHelper.UserApi.Search(text);
    setSearchData(data);
  }

  const onSearchChange = value => {

    setSearch(value);
    setShowSearch(value.length>=2);

    const text = value;
    if(interval)
    {
      clearTimeout(interval);
    }

    setInterval(setTimeout(() => {
      //search function
      loadSearch(text);
    }, 1000));
  }
  if(!AdminHelper.checkAdminAccount())
  {
    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }

  const dataTable = (id=0) => {
    let arr = AdminHelper.nestedArray(_.cloneDeep(items));
    return arr;
  }
  const handleActions = (type,item) => {
    console.log(type,item);
    if(type=='edit')
    {
      setShowEdit(_.cloneDeep(item));
    }
    if(type=='delete')
    {
      confirm({ title:'Alert',description: 'Do you want to delete this truck?',confirmText:'Delete now',cancelText:'Cancel' })
          .then( () => {
            deleteItem(item);
            //console.log('ok');
          }).catch(()=>{
            //console.log('cancel');
          });

    }
    if(type=='showFiles')
    {
      setShowFiles(_.cloneDeep(item));
    }
  }
  const handleToolbarActions = (type) => {
    console.log(type);
    if(type=='add')
    {
      setShowEdit(_.cloneDeep(defaultItem));
    }
  }
  const deleteItem = async (item) => {
    //let rel = CommonHelper.postFormData(CommonHelper.DELETEFOLDER_PATH,{folder:"videos/"+item.user});

    let id = await AdminHelper.UserApi.DeleteUser(item.id);
    if(id!=0)
    {
      CommonHelper.NotificationManager.success('Success');
      setTimeout(loadItems,500);
    }
    else
    {
      CommonHelper.NotificationManager.error('Please try again');
    }
  }
  const handleSuccess = () => {
    setShowEdit(false);
    setShowFiles(false);
    setTimeout(loadItems,500);
  }

  return (
    <div className={classes.root}>
      {!showEdit?<DataToolbar title="Trucks" actions={toolbarActions} enableSearch={true} onActionCallback={handleToolbarActions} onSearchChange={onSearchChange} />:false}
      <div className={classes.content}>

        {showSearch?<DataTable key={'1'} handleActions={handleActions} dataActions={dataActions} mobileLayout={true} dataValues={dataValues} data={searchData} rows={100} />:
        <DataTable key={'2'} handleActions={handleActions} loadNextItems={loadNextItems} mobileLayout={true} dataActions={dataActions} dataValues={dataValues} data={items} rows={10} total={items.length<10?items.length:-1} />}
      </div>
      <Modal
      open={showEdit}
      onClose={()=>setShowEdit(null)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
    <div className={classes.paper}>
      <EditComp onSuccess={handleSuccess} onCancel={()=>setShowEdit(null)} item={showEdit} />
    </div>
    </Modal>
    <Modal
    open={showFiles}
    onClose={()=>setShowFiles(null)}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className={classes.modal}
  >
  <div className={classes.paper}>
    <FileComp onSuccess={handleSuccess} onCancel={()=>setShowFiles(null)} item={showFiles} />
  </div>
  </Modal>
    </div>
  );
};

export default UserList;
