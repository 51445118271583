import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import lodash from 'lodash';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },

  signInButton: {
    margin: theme.spacing(2, 0)
  },content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
  ,
  form:{
    flexBasis: 500,
  }
}));

const Edit = props => {
  const {item,onCancel,onSuccess,cats} = props;

  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);

  const fields = {
    name: {
      type:'text',
      label:'Name',
      value:item.name,
      required:true,
      validate:{
        presence: true,
        length: {
          minimum: 1
        }
      }
    },
    password: {
      type:'password',
      label:'Current password',
      validate:{
        presence: true,
        length: {
          minimum: 6
        }
      }
    },
    new_password: {
      type:'password',
      label:'New Password',
      validate:{

      }
    },
    cpassword: {
      type:'password',
      label:'Confirm new password',
      validate:{

      }
    }
  }

  const handleFormChange = myForm => {
    setState(state=>({...state,myForm}));
  }

  const confirmClick = event => {
      event.preventDefault();
      updateData();
  }

  const updateData = async () =>{

    let newItem = {...state.myForm.values};
    newItem.id = item.id;
    if(newItem.new_password&&newItem.new_password.length>0)
    {
      if(newItem.new_password.length<6)
      {
        CommonHelper.NotificationManager.error("Password must be 6 characters or more");
        return;
      }
      if(newItem.new_password!=newItem.cpassword)
      {
        CommonHelper.NotificationManager.error("Confirm password not correct");
        return;
      }
    }
    else {
      delete newItem['new_password'];
    }
    delete newItem['cpassword'];

await setLoading(true);
      let data = await AdminHelper.AdminApi.Update(newItem);
      if(data)
      {
        CommonHelper.NotificationManager.success("Success");
        onSuccess(data);
      }
      else {
        CommonHelper.NotificationManager.error("Password not correct");
      }

    await setLoading(false);
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  ACCOUNT
                </Typography>
                <JForm fields={fields} handleFormChange={handleFormChange}  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!state.myForm.isValid}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={confirmClick}
                  >
                    {loading?<CircularProgress color="secondary" />:item.id=='0'?'Add':'Update'}
                  </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object
};

export default Edit;
