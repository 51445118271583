import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,Typography } from '@material-ui/core';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import {DataTable,DataToolbar} from 'components';
import {EditComp} from './components';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(1)
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  }
  ,
  note: {
    whiteSpace: 'pre-wrap'
  }
}));


const ProductList = (props) => {
  const {user,category} = props;
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme();
  const [search, setSearch] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [showEdit, setShowEdit] = React.useState(null);
  const [interval, setInterval] = React.useState(null);
  const [searchData, setSearchData] = React.useState([]);
  const [showSearch, setShowSearch] = React.useState(false);
  const [counter, setCounter] = React.useState(null);
  const [openLB, setOpenLB] = React.useState(null);
  const [cats, setCats] = React.useState([]);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const dataValues = [
    {label:'Tên',value:'name',className:classes.link,action:'edit',hidden:{smDown:true}},
    {label:'Danh mục',value:'category_name',hidden:{smDown:true}},
    {label:'Số xe/Số imei/Số khung',value:'identify',className:classes.link,action:'edit'},
    {label:'Hình',value:'images',className:classes.link,type:'imagelist',action:'show_image'},
    {label:'Ghi chú',width:250,value:'note',className:classes.note,maxLength:100},
    {label:'Ngày Tạo',width:150,value:'created',align:'right',dateFormat:'DD/MM/YYYY HH:mm:ss',hidden:{smDown:true}}];
  const dataActions = [{name:'Sửa',type:'edit'},{name:'Xoá',type:'delete',color:'red'}];
  const toolbarActions = [{name:'Thêm mới',type:'add'}];

  const defaultItem = {
    id:0,
    name:"",
    category:cats&&cats.length>0?cats[0].id:"",
    identify:"",
    id_user:"",
    note:""
  }

  React.useEffect(()=>{
    loadCats();
    loadItems();
    loadCounter();
  },[])
  const loadCounter = async () =>{
    let data = await AdminHelper.ReportApi.Counter();
    setCounter(data);
  }
  const loadCats = async () =>{
    let data = await AdminHelper.CategoryApi.GetAll();
    data = _.sortBy(data,['name'],['asc']);
    setCats(data);
  }
  const loadItems = async () =>{
    if(category)
    {
      let data = await AdminHelper.ProductApi.GetAll({category:category.id});
      setItems(data);
    }
    else
    if(user)
    {
      let data = await AdminHelper.ProductApi.GetByUser(user.id);
      setItems(data);
    }
    else {
      let data = await AdminHelper.ProductApi.GetAll();
      setItems(data);
    }
  }
  const loadNextItems = async (after) => {
    let rel = await AdminHelper.ProductApi.GetAll({after,category:category?category.id:false});
    if(rel)
    {
      let arr = items.concat(rel);
      setItems(arr);
    }
  }
  const loadSearch = async (text) =>{
    if(user)
    {
      let arr = [];
      for(let obj of items)
      {
        if(_.include(obj.search,text))
        {
          arr.push(obj);
        }
      }
      setSearchData(arr);
    }
    else {
    let data = await AdminHelper.ProductApi.Search(text);
    setSearchData(data);
    }
  }

  const getDataTable = () => {
    let data = _.cloneDeep(items);
    if(cats)
    for(let obj of data)
    {
      let arr = _.filter(cats, {'id':obj.category});
      if(arr&&arr.length>0)
      obj.category_name = arr[0].name;
    }
    return data;
  }
  const getSearchDataTable = () => {
    let data = _.cloneDeep(searchData);
    if(cats)
    for(let obj of data)
    {
      let arr = _.filter(cats, {'id':obj.category});
      if(arr&&arr.length>0)
      obj.category_name = arr[0].name;
    }
    return searchData;
  }

  const onSearchChange = value => {

    setSearch(value);
    setShowSearch(value.length>=2);

    const text = value;
    if(interval)
    {
      clearTimeout(interval);
    }

    setInterval(setTimeout(() => {
      //search function
      loadSearch(text);
    }, 500));
  }
  if(!AdminHelper.checkAdminAccount())
  {
    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }


  const handleActions = async (type,item) => {
    if(type=='edit')
    {

      let newItem = _.cloneDeep(item);
      if(user)
      newItem.id_user = user;
      else
      {
        let user = await AdminHelper.UserApi.GetById(item.id_user);
        newItem.id_user = user;
      }
      setShowEdit(_.cloneDeep(newItem));
    }
    if(type=='delete')
    {
      deleteItem(item)
    }
    if(type=='show_image')
    {
      setPhotoIndex(0);
      setOpenLB(item.images);
    }
  }
  const handleToolbarActions = (type) => {
    console.log(type);
    if(type=='add')
    {
      setShowEdit(_.cloneDeep(defaultItem));
    }
  }
  const deleteItem = async (item) => {
    let id = await AdminHelper.ProductApi.Delete(item.id);
    if(id!=0)
    {
      CommonHelper.NotificationManager.success('Đã xoá thành công');
      setTimeout(loadItems,500);
    }
    else
    {
      CommonHelper.NotificationManager.error('Hãy thử lại');
    }
  }
  const handleSuccess = () => {
    setShowEdit(false);
    setTimeout(loadItems,500);
  }

  return (
    <div className={classes.root}>
      {!showEdit?<DataToolbar title="Sản phẩm" actions={toolbarActions} enableSearch={user?false:true} onActionCallback={handleToolbarActions} onSearchChange={onSearchChange} />:false}
      <div className={classes.content}>
        {showEdit?<EditComp user={user} category={category} onSuccess={handleSuccess} cats={cats} onCancel={()=>setShowEdit(null)} item={showEdit} />:false}
        {!showEdit&&counter?showSearch?<DataTable key={1} handleActions={handleActions} dataActions={dataActions} dataValues={dataValues} data={getSearchDataTable()} rows={100} />:
        <DataTable key={2} handleActions={handleActions} loadNextItems={loadNextItems} dataActions={dataActions} dataValues={dataValues} data={getDataTable()} rows={user?100:10} total={user?user.Product:category?category.Product:counter.Product} />:false}
      </div>
      {openLB && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 9999 } }}
            mainSrc={openLB[photoIndex]}
            nextSrc={openLB[(photoIndex + 1) % openLB.length]}
            prevSrc={openLB[(photoIndex + openLB.length - 1) % openLB.length]}
            onCloseRequest={() => setOpenLB(null)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + openLB.length - 1) % openLB.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % openLB.length)
            }

          />
        )}
    </div>
  );
};

export default ProductList;
