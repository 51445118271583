import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import * as Layouts from './layouts';
import * as AdminViews from 'views/Admin';
import * as SiteViews from 'views/Site';
import {AdminHelper,SiteHelper} from 'helpers';
const Routes = () => {

  return (
    <Switch>

      <Redirect
        exact
        from={AdminHelper.getAdminPath()}
        to={AdminHelper.getAdminPath()+"/dashboard"}
      />
      <Redirect
        exact
        from={'/'}
        to={AdminHelper.getAdminPath()+"/dashboard"}
      />
    {AdminHelper.getAdminRoutes().map((prop)=>{
      return (<RouteWithLayout key={prop.name}
        component={AdminViews[prop.component]}
        exact
        layout={Layouts[prop.layout]}
        path={AdminHelper.getAdminPath()+prop.path}
      />)
    })}
    <RouteWithLayout
      component={AdminViews['NotFound']}
      exact
      layout={Layouts['Minimal']}
      path={AdminHelper.getAdminPath()+"/not-found"}
    />
  <Redirect to={AdminHelper.getAdminPath()+"/not-found"} />
    </Switch>
  );
};

export default Routes;
