import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,Typography } from '@material-ui/core';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import {DataTable,DataToolbar} from 'components';
import {EditComp} from './components';
import _ from 'lodash';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  }
}));
const defaultItem = {
  id:0,
  name:"",
  slug:"",
  parent:"0"
}

const Category = () => {
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme();

  if(!AdminHelper.checkAdminAccount())
  {
    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }



  const handleSuccess = (user) => {
    let ACCOUNT = user;

    CommonHelper.saveLocalData('ADMINACCOUNT',ACCOUNT);
    AdminHelper.getAdminStore().dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:ACCOUNT });
  }

  return (
    <div className={classes.root}>
        <EditComp onSuccess={handleSuccess}  item={AdminHelper.getAdminStore().getState().ADMINACCOUNT} />
    </div>
  );
};

export default Category;
