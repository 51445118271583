import React, { useState, useEffect } from 'react';
import { Link as RouterLink,Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress,
  Container
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const fields = {
  email: {
    type:'text',
    label:'username',
    validate:{
      presence: true,
      email: false
    }
  },
  password: {
    type:'password',
    label:'Password',
    validate:{
      presence: true,
      length: {
        minimum: 6
      }
    }
  },
  remember: {
    type:'checkbox',
    label:'Remember me',
    validate:{
    }
  }
}


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.primary.dark,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: '',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const history  = useHistory();

  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);




  const handleBack = () => {
    history.goBack();
  };
  const handleFormChange = myForm => {
    setState(state=>({...state,myForm}));
  }

  const handleSignIn = event => {
    event.preventDefault();
    history.push('/');
  };



  const signIn = async () =>{
    await setLoading(true);
    let {email,password,remember} = state.myForm.values;
    let data = await AdminHelper.AdminApi.Login(email,password);
    if(!data)
    {
      CommonHelper.NotificationManager.error("email or password not correct!");
    }
    else {
        let ACCOUNT = data.user;
        let TOKEN = data.token;
        CommonHelper.saveLocalData('ADMINACCOUNT',ACCOUNT);
        AdminHelper.getAdminStore().dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:ACCOUNT });
        if(remember)
        {
          CommonHelper.saveLocalData('ADMINTOKEN',TOKEN);
        }
        history.replace('/admin');
    }
    await setLoading(false);

    // AdminHelper.getAdminStore().dispatch({ type: 'ACCOUNT', ACCOUNT:state.myForm.values });
    // history.replace('/admin');
  }
  if(AdminHelper.checkAdminAccount())
  {

    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()
            }}
          />)
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}  >

                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Sign In
                </Typography>
                <JForm fields={fields} handleFormChange={handleFormChange}  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!state.myForm.isValid}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={()=>signIn()}
                  >
                    {loading?<CircularProgress color="secondary" />:'Sign In'}
                  </Button>




      </Container>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default SignIn;
