import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Paper, Input,Fab,Typography,Divider,ButtonBase,IconButton } from '@material-ui/core';
import {useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Carousel from "react-multi-carousel";
const useStyles = makeStyles(theme => ({
  root: {

    borderRadius: '5px',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  item:{
    padding: theme.spacing(0.5),
  },
  boxTitle: {
    padding:theme.spacing(1)
  },
  catgroup:{
    display:'flex',
    flexDirection:'row',
  },
  catitem:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    width:120,
    height:150,
    marginLeft: theme.spacing(1),
  },
  container: {

  },
  item_image: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition:'center',
    width:83,
    height:75
  },
  item_name: {
    height:40
  }

}));

const CategoryList = props => {
  const { className,items, onChange, style, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: items.length>9?9:items.length
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: items.length>5?5:items.length
    },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: items.length>2?2:items.length
  }
  };
  const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (<IconButton style={{left:1,position:'absolute'}} onClick={() => onClick()}><ArrowBackIosIcon style={{color:theme.palette.primary.main}} /></IconButton>);
};
const CustomRightArrow = ({ onClick, ...rest }) => {
const {
  onMove,
  carouselState: { currentSlide, deviceType }
} = rest;
// onMove means if dragging or swiping in progress.
return (<IconButton style={{right:1,position:'absolute'}} onClick={() => onClick()}><ArrowForwardIosIcon style={{color:theme.palette.primary.main}} /></IconButton>);
};
const onCatClick = (cat) =>{
  history.push("/"+cat.slug+"/c-"+cat.id)
}

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >

    <Carousel containerClass={classes.container} customLeftArrow={<CustomLeftArrow />} customRightArrow={<CustomRightArrow />} renderButtonGroupOutside  itemClass={classes.item} responsive={responsive}>
      {items.map((item)=>{
        return (<ButtonBase key={item.id} className={classes.catitem} onClick={()=>onCatClick(item)}>
        <div className={classes.item_image} style={{backgroundImage:'url('+(item.image&&item.image.length>0?item.image:'/images/sites/cat_x126.png')+')'}}></div>
          <Typography variant="h6" className={classes.item_name}>{item.name}</Typography>
        </ButtonBase>);
      })}
    </Carousel>

    </Paper>
  );
};

CategoryList.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  items: PropTypes.array
};
CategoryList.defaultProps = {
  items:[]
}

export default CategoryList;
