import * as CommonHelper from 'helpers/Common';
const GETALL_API = CommonHelper.ADMIN_SERVER+'Category-GetAll';
const ADD_API = CommonHelper.ADMIN_SERVER+'Category-Add';
const UPDATE_API = CommonHelper.ADMIN_SERVER+'Category-Update';
const DELETE_API = CommonHelper.ADMIN_SERVER+'Category-Delete';
export async function GetAll(){
    let result = await CommonHelper.loadJSONData(GETALL_API);
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}

export async function Add(data){
    let result = await CommonHelper.postFormData(ADD_API,data);
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function Update(data){
    let result = await CommonHelper.postFormData(UPDATE_API,data);
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function Delete(id){
    let result = await CommonHelper.postFormData(DELETE_API,{id});
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
