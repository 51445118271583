import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import 'react-image-lightbox/style.css';
import Routes from './Routes';
import 'react-notifications/lib/notifications.css';
import 'react-multi-carousel/lib/styles.css';
import {NotificationContainer} from 'react-notifications';
import {AdminHelper,CommonHelper} from 'helpers';
import moment from 'moment';
import { ConfirmProvider } from 'components/ConfirmDialog';
import * as firebase from 'firebase/app';
!firebase.apps.length?firebase.initializeApp(CommonHelper.FIREBASE):firebase.firestore();
  const App = () =>  {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(()=>{
    loadUserData();
  },[]);

  const loadUserData = () => {
    let ADMINACCOUNT = CommonHelper.loadLocalData('ADMINACCOUNT');
    if(ADMINACCOUNT)
    {
      let ADMINTOKEN = CommonHelper.loadLocalData('ADMINTOKEN');
      if(ADMINTOKEN&&ADMINTOKEN.id_user == ADMINACCOUNT.id && moment(ADMINTOKEN.expired).isAfter(moment()))
      {
        checkToken(ADMINACCOUNT,ADMINTOKEN);
        AdminHelper.getAdminStore().dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:ADMINACCOUNT });
      }
      else {
        CommonHelper.deleteLocalData('ADMINACCOUNT');
        CommonHelper.deleteLocalData('ADMINTOKEN');
      }

    }
    setLoading(false);

  }

  const checkToken = async(ADMINACCOUNT,ADMINTOKEN) => {
    let data = await AdminHelper.AdminApi.CheckToken(ADMINACCOUNT,ADMINTOKEN);
    if(data)
    {
      if(data.status==0)
      {
        AdminHelper.getAdminStore().dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:false });
        CommonHelper.NotificationManager.error('Your session has been expired!');
        CommonHelper.deleteLocalData('ADMINACCOUNT');
        CommonHelper.deleteLocalData('ADMINTOKEN');
      }
      else {
        CommonHelper.saveLocalData('ADMINTOKEN',data.data.token);
      }
    }
  }

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ConfirmProvider>
          {loading?true:<Routes />}
          <NotificationContainer/>
          </ConfirmProvider>
        </BrowserRouter>
      </ThemeProvider>
    );

}
export default App;
