export { NotificationManager} from 'react-notifications';
//export const ADMIN_SERVER = "http://localhost:4000/";
export const ADMIN_SERVER = "https://video.diycam.net/";
export const VIDEO_PATH = ADMIN_SERVER+"videos/";
export const API_UPLOAD = ADMIN_SERVER+"upload";
export const DELETE_PATH = ADMIN_SERVER+"delete";
export const DELETEFOLDER_PATH = ADMIN_SERVER+"deleteFolder";
export const ADMIN_PATH = "/admin";
export { useConfirm } from 'components/ConfirmDialog';
export const Version = "v1.1.0";
export async function loadJSONData(url) {
  try {
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const FIREBASE = {
  apiKey: "AIzaSyD0ucqE5WBWyRr42hTIRGG1gWg26j5BfBQ",
  authDomain: "jjrpum.firebaseapp.com",
  projectId: "jjrpum",
  storageBucket: "jjrpum.appspot.com",
  messagingSenderId: "871827474368",
  appId: "1:871827474368:web:b97a18e1ebb1c72fccea25",
  measurementId: "G-VLBSZDJ8PB"
}

export async function postFormData(url, data) {


  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function postJSONData(url, data) {

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseJson = await response.text();
    return responseJson;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function postFormData2(url,data) {

// console.log(url);
// return;
  let formData = new FormData();
  // Assume "photo" is the name of the form field the server expects

for (var property in data) {

  formData.append(property, data[property]);
}

try {
const response = await fetch(url, {
  method: 'POST',
  body: formData,
  header: {
    'content-type': 'multipart/form-data',
  }
});
const responseJson = await response.json();
return responseJson;
} catch(error){
     console.error(error);
     return null;
}
}

export function loadLocalData(key) {
  let data = localStorage.getItem(key);
  if(data)
  {
      data = JSON.parse(data);
  }
  return data;
}

export function saveLocalData(key,data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function deleteLocalData(key) {
  localStorage.removeItem(key);
}

export function getInitials (name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}
export function slugify(name)
{
    if(!name||name.trim.lenght==0)
    return '';
    var slug;
     slug = name.toLowerCase();

     //Đổi ký tự có dấu thành không dấu
     slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
     slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
     slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
     slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
     slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
     slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
     slug = slug.replace(/đ/gi, 'd');
     //Xóa các ký tự đặt biệt
     slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
     //Đổi khoảng trắng thành ký tự gạch ngang
     slug = slug.replace(/ /gi, "-");
     //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
     //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
     slug = slug.replace(/\-\-\-\-\-/gi, '-');
     slug = slug.replace(/\-\-\-\-/gi, '-');
     slug = slug.replace(/\-\-\-/gi, '-');
     slug = slug.replace(/\-\-/gi, '-');
     //Xóa các ký tự gạch ngang ở đầu và cuối
     slug = '@' + slug + '@';
     slug = slug.replace(/\@\-|\-\@|\@/gi, '');
     return slug;
}
