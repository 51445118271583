import * as CommonHelper from 'helpers/Common';
const COUNTER_API = CommonHelper.ADMIN_SERVER+'Report-Counter';
const COUNTERBYDATE_API = CommonHelper.ADMIN_SERVER+'Report-CounterByDate';
export async function Counter(){
    let result = await CommonHelper.postFormData(COUNTER_API,{});
    let rel = null;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function CounterByDate(start,end,type){
    let result = await CommonHelper.postFormData(COUNTERBYDATE_API,{start,end,type});
    let rel = null;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
