import * as CommonHelper from 'helpers/Common';
import AdminStore from 'store/admin';
import lodash from 'lodash';
export {CategoryApi,AdminApi,UserApi,ProductApi,ReportApi,JobApi,DocApi} from './AdminApis';



export function getAdminRoutes(){
  return [
    {
      name:'Dashboard',
      path:'/dashboard',
      component:'Dashboard',
      layout:'Main',
      icon:'Dashboard',
      href:'/dashboard'
    },
    {
      name:'Trucks',
      path:'/trucks/:id?/:action?',
      component:'UserList',
      layout:'Main',
      icon:'LocalShipping',
      href:'/trucks'
    },
    {
      name:'Jobs',
      path:'/jobs/:id?/:action?',
      component:'JobList',
      layout:'Main',
      icon:'People',
      href:'/jobs'
    },
    {
      name:'Documents',
      path:'/documents/:id?/:action?',
      component:'DocList',
      layout:'Main',
      icon:'Assignment',
      href:'/documents'
    },
    {
      name:'Settings',
      path:'/settings',
      component:'Setting',
      layout:'Main',
      icon:'Settings',
      href:'/settings'
    },

    {
      name:'Tutorial Videos',
      path:'/tvideo',
      component:'TVideo',
      layout:'Main',
      icon:'Info',
      href:'/tvideo'
    }
    ,
    {
      name:'Logout',
      path:'/logout',
      component:'Logout',
      layout:'Main',
      icon:'Input',
      href:'/logout'
    },
    {
      name:'Sign In',
      path:'/login',
      component:'SignIn',
      layout:'Minimal'
    }
  ]
}

export function getAdminPath(){
  return CommonHelper.ADMIN_PATH;
}
export function getAdminStore(){
  return AdminStore;
}
export function getAdminStoreValue(key){
  return AdminStore.getState()[key];
}
export function checkAdminAccount(){
  return AdminStore.getState().ADMINACCOUNT!=false;
}
export function nestedArray(results)
{
  let root = lodash.cloneDeep(lodash.filter(results, {'parent':'0'}));
  getChilds(root,results);
  return root;
}
function getChilds(root,results)
{
  for(let child of root)
  {
    let childs = lodash.cloneDeep(lodash.filter(results, {'parent':child.id}));
    if(childs && childs.length>0)
    {
      child.childs = childs;
      getChilds(childs,results);
    }
  }
}
