import { createStore } from 'redux';


const reducer = (state, action) => {

    if (action.type === "ACCOUNT") {
        return {
            ...state,
            ACCOUNT: action.ACCOUNT
        }
    }
    if (action.type === "LOADING") {
        return {
            ...state,
            LOADING: action.LOADING
        }
    }
    if (action.type === "LANG") {
        return {
            ...state,
            LANG: action.LANG
        }
    }

    return state;
}

export default createStore(reducer, {ACCOUNT:false,LANG:'en',LOADING:false });
