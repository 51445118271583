import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ArrowBackIcon,CheckBoxOutlineBlankIcon,CheckBoxIcon} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    flexBasis:theme.breakpoints.values['md'],
  },
  textField: {
  },
}));
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const JForm = props => {
  const { params,fields, ...rest } = props;
  const [state,setState] = useState({isValid:false,values: {},touched: {},errors: {},schema:{}});
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [interval, setInterval] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [autoKeys, setAutoKeys] = React.useState([]);
  let schema = {};
  Object.keys(fields).forEach(key=>{
    if(fields[key].validate)
    {
      schema[key]=fields[key].validate;
    }
  })
  useEffect(() => {
    const errors = validate(state.values, schema);
    setState(state=>({
      ...state,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    if(props.handleFormChange)
    props.handleFormChange({...state,isValid: errors ? false : true,
    errors: errors || {}});
  }, [state.values]);
  useEffect(() => {
    let values = {};
    let autoKeys = [];
    Object.keys(fields).forEach(key=>{
      if(fields[key].value)
      {
        values[key] = fields[key].value;
        if(fields[key].type=='autocomplete')
        {
          autoKeys.push(key);
          setInputValue(fields[key].value.name+'('+fields[key].value.phone+')');
        }
      }
    })
    setAutoKeys(autoKeys);
    setState(state => ({
      ...state,
      values}));

  }, []);

  React.useEffect(() => {
    if (!open) {
      for(let key of autoKeys)
      {
        setInputValue(state.values[key].name+'('+state.values[key].phone+')');
      }

    }
  }, [open])

  const onAutoInputChange = async (event, newInputValue,fetchData) => {
    setInputValue(newInputValue);
    const text = newInputValue;
    if(text.length>=3)
    {
      if(interval)
      {
        clearTimeout(interval);
      }

      setInterval(setTimeout(async () => {
        //search function
        let data = await fetchData(text);
        setOptions(data);
      }, 1000));
    }


  }

  const hasError = field =>
    state.touched[field] && state.errors[field] ? true : false;
  const handleChange = event => {
    console.log('handleChange');
    event.persist();
    const {name,value,type,checked} = event.target;

    setState(state => ({
      ...state,
      values:{
        ...state.values,
        [name]:type === 'checkbox'?checked:value
      },
      touched: {
        ...state.touched,
        [name]: true
      }
    }));
  };
  const getAutoComplete = (field,key) =>{
    return (<Grid key={key} item xs={field.xs?field.xs:12} md={field.md?field.md:12}>
      <Autocomplete
      id="asynchronous-demo"
      style={{ width: '100%' }}
      open={open}
      value={state.values[key]}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        setState(state => ({
          ...state,
          values:{
            ...state.values,
            [key]:newValue
          },
          touched: {
            ...state.touched,
            [key]: true
          }
        }));
        }}


      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name+'('+option.phone+')'}
      options={options}
      loading={loading}

      onInputChange={(event,newInputValue)=>onAutoInputChange(event,newInputValue,field.fetchData)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    /></Grid>);
  }
  const getTextField = (field,key) =>
  {
    return (<Grid key={key} item xs={field.xs?field.xs:12} md={field.md?field.md:12}>
      <TextField
        className={classes.textField}
        error={hasError(key)}
        helperText={
          hasError(key) ? state.errors[key][0] : null
        }
        {...field.multiline?{multiline:true,rows:field.multiline}:false}
        fullWidth
        {...field.required?{required:true}:false}
        label={field.label}
        name={key}
        onChange={handleChange}
        type={field.type}
        value={state.values[key] || ''}
        variant={field.variant?field.variant:"outlined"}
      />
  </Grid>);
  }

  const getSelect = (field,key) =>
  {
    return (<Grid key={key} item xs={field.xs?field.xs:12} md={field.md?field.md:12}>
      <TextField
        className={classes.textField}
        error={hasError(key)}
        helperText={
          hasError(key) ? state.errors[key][0] : null
        }
        {...field.required?{required:true}:false}
        fullWidth
        label={field.label}
        name={key}
        onChange={handleChange}
        select
        value={state.values[key] || ''}
        variant={field.variant?field.variant:"outlined"}
      >
      {field.data.map((item)=>{
        return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
      })}
    </TextField>
  </Grid>);
  }
  const getCheckBox = (field,key) =>
  {
    return (<Grid key={key} item xs={field.xs?field.xs:12} md={field.md?field.md:12}>
      <FormControlLabel
      control={
        <Checkbox
          checked={state.values[key] || false}
          onChange={handleChange}
          name={key}
          color="primary"
        />
      }
      label={field.label}
    />
  </Grid>);
  }

  return (
    <div className={classes.root}>
    <Grid container spacing={2}>
        {Object.keys(fields).map((key,index)=>{
          const field = fields[key];

          if(field.type=="text"||field.type=="password"||field.type=="hidden")
          {
            return getTextField(field,key);
          }
          if(field.type=="checkbox")
          {
            return getCheckBox(field,key);
          }
          if(field.type=="select")
          {
            return getSelect(field,key);
          }
          if(field.type=="autocomplete")
          {
            return getAutoComplete(field,key);
          }
          return ;
        })}

    </Grid>
  </div>
  );
};

JForm.propTypes = {
  fields:PropTypes.object.isRequired,
};

JForm.defaultProps = {
  fields:{}
};

export default JForm;
