import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles,useTheme } from '@material-ui/styles';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Popover,
  IconButton,
  Paper,
  Hidden
} from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getInitials } from 'helpers';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {overflow:'auto'},
  content: {
    padding: 0
  },
  inner: {

    overflow:'auto'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  popover: {
    display:'flex',
    flexDirection:'column'
  },
  list:{
    minWidth:150
  },
  redItemText:{
    color:'red'
  },
  actionIcon:{
    backgroundColor:theme.palette.primary.main,
    color:'white',
    '&:hover': {
       background: theme.palette.secondary.main,
    },
  },container: {

  },
}));

const DataTable = props => {
  const { className,dataValues,dataActions,handleActions,customActions,loadNextItems,rows,total, data,mobileLayout,minWidth, ...rest } = props;

  const classes = useStyles();

  const [selectedData, setSelectedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(rows||10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const handleSelectAll = event => {
    const { data } = props;

    let selectedData;

    if (event.target.checked) {
      selectedData = data.map(item => item._id);
    } else {
      selectedData = [];
    }

    setSelectedData(selectedData);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedData.indexOf(id);
    let newSelectedData = [];

    if (selectedIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, id);
    } else if (selectedIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      );
    }

    setSelectedData(newSelectedData);
  };

  const handlePageChange = async (event, page) => {

    let cData = _.cloneDeep(data);
    console.log(cData);
    let arr = data.slice(page*rowsPerPage, (page+1)*rowsPerPage);
    if(arr.length==0)
    {
      let item = cData.splice(-1)[0];
      //console.log(item.id);
      await loadNextItems(item.id)
    }
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  let actions = dataActions;
  if(customActions)
  actions = customActions(selectedItem);
  if(mobileLayout&&!isDesktop)
  return (<Card
    {...rest}
    className={clsx(classes.root, className)}
  >
    <CardContent className={classes.content}>

        <div className={classes.inner} style={{pading:10}}>

          {data.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(item => (
            <Paper
              elevation={5}
              key={item._id}
              style={{padding:10,marginTop:10,display:'flex',flexDirection:'row'}}
            >

          <div style={{flexGrow:1}}>
              {dataValues.map((obj)=>{
                return <div  style={{display:obj.hidden?'none':'flex',flexDirection:'row'}} key={obj.value}><div style={{marginRight:5}}><b>{obj.label}: </b></div><div {...obj} onClick={()=> obj.action?handleActions(obj.action,item):false} >
                  {obj.type=='custom'?obj.renderItem(item):obj.type=='imagelist'?(
                    item[obj.value].map((image,index)=>
                      (<img key={index} src={image} style={{width:'50px'}} />)
                    )
                  ):obj.type=='image'?<img src={item[obj.value]} style={{width:'100px'}} />:
                  obj.dateFormat?moment(item[obj.value]).format(obj.dateFormat):obj.maxLength?item[obj.value].substr(0,obj.maxLength)+(item[obj.value].length>obj.maxLength?"...":""):item[obj.value]}</div>
              </div>
              })}
            </div>
            <div >

              <IconButton size="small" className={classes.actionIcon}  edge="end" aria-label="" onClick={(e)=>{setAnchorEl(e.target);setSelectedItem(item);setOpen(true);}}>

              <MoreVertIcon />
            </IconButton></div>




          </Paper>

          ))}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={()=>setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
          {actions.map((obj,index)=>{
            return (<ListItem key={obj.type} onClick={(e)=>{
              if(handleActions)
              handleActions(obj.type,selectedItem)
              setOpen(false);
            }}
              button>
              <ListItemText primary={<Typography type="body2" style={{ color: obj.color?obj.color:'inherit' }}>{obj.name}</Typography>}  />
            </ListItem>);
          })}

          </List>

    </Popover>

        </div>

    </CardContent>

    <CardActions className={classes.actions}>
      <TablePagination
        component="div"
        count={total||data.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
      />
    </CardActions>
  </Card>);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>

          <div className={classes.inner} style={{minWidth:minWidth}}>
            <TableContainer className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>

                  {dataValues.map((obj)=>{
                    return <Hidden {...obj.hidden?{...obj.hidden}:false} key={obj.value}><TableCell {...obj.align?{align:obj.align}:false} style={{width:obj.width?obj.width:'auto',borderRight:"1px solid #eeeeee"}} key={obj.value}>{obj.label}</TableCell></Hidden>
                  })}
                  <TableCell style={{width:50,borderRight:"1px solid #eeeeee"}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item._id}
                    selected={selectedData.indexOf(item._id) !== -1}
                  >

                    {dataValues.map((obj)=>{
                      return <Hidden {...obj.hidden?{...obj.hidden}:false} key={obj.value}><TableCell style={{borderRight:"1px solid #eeeeee"}} {...obj} onClick={()=> obj.action?handleActions(obj.action,item):false} >
                        {obj.type=='custom'?obj.renderItem(item):obj.type=='imagelist'?(
                          item[obj.value].map((image,index)=>
                            (<img key={index} src={image} style={{width:'50px'}} />)
                          )
                        ):obj.type=='image'?<img src={item[obj.value]} style={{width:'100px'}} />:
                        obj.dateFormat?moment(item[obj.value]).format(obj.dateFormat):obj.maxLength?item[obj.value].substr(0,obj.maxLength)+(item[obj.value].length>obj.maxLength?"...":""):item[obj.value]}</TableCell>
                      </Hidden>
                    })}
                    <TableCell style={{borderRight:"1px solid #eeeeee"}}>

                      {actions&&actions.length>0&&<IconButton size="small" className={classes.actionIcon}  edge="end" aria-label="" onClick={(e)=>{setAnchorEl(e.target);setSelectedItem(item);setOpen(true);}}>

                      <MoreVertIcon />
                    </IconButton>}</TableCell>



                  </TableRow>

                ))}
              </TableBody>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={()=>setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.list}
              >
              {actions.map((obj,index)=>{
                return (<ListItem key={obj.type} onClick={(e)=>{
                  if(handleActions)
                  handleActions(obj.type,selectedItem)
                  setOpen(false);
                }}
                  button>
                  <ListItemText primary={<Typography type="body2" style={{ color: obj.color?obj.color:'inherit' }}>{obj.name}</Typography>}  />
                </ListItem>);
              })}

              </List>

        </Popover>
            </Table>
          </TableContainer>
          </div>

      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={total||data.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
        />
      </CardActions>
    </Card>
  );
};
DataTable.defaultProps = {
  mobileLayout:false,
  minwdith:0
};
DataTable.propTypes = {
  dataActions:PropTypes.array,
  customActions:PropTypes.func,
  handleActions:PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataValues: PropTypes.array.isRequired,
  rows:PropTypes.number,
  total:PropTypes.number,
  loadNextItems:PropTypes.func,
  mobileLayout:PropTypes.bool,
  minWidth:PropTypes.number
};

export default DataTable;
