import * as CommonHelper from 'helpers/Common';
import _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
!firebase.apps.length?firebase.initializeApp(CommonHelper.FIREBASE):firebase.firestore();
const collection = "Trucks";
const firestore = firebase.firestore();
const GETALL_API = CommonHelper.ADMIN_SERVER+'User-GetAll';
const CREATE_FOLDER = CommonHelper.ADMIN_SERVER+'create_folder';
const UPDATE_API = CommonHelper.ADMIN_SERVER+'User-Update';
const DELETE_API = CommonHelper.ADMIN_SERVER+'User-Delete';
const GETBYID_API = CommonHelper.ADMIN_SERVER+'User-GetById';
const CHECKID_API = CommonHelper.ADMIN_SERVER+'User-CheckID';
const SEARCH_API = CommonHelper.ADMIN_SERVER+'User-Search';
export async function GetAll(params={}){

      let docRef = firestore.collection(collection).orderBy("created","desc").limit(10);
        if(params.after)
        {
          let doc = await firestore.collection(collection).doc(params.after).get();
          docRef = docRef.startAfter(doc);
        }

          let data = await docRef.get();

        let results = [];
        if(data.size>0)
        {
          data.forEach((doc)=>{
            let item = doc.data();
            item.id = doc.id;
            results.push(item);
          })
        }

    return results;
}
export async function GetAllTVideo(params={}){

      let docRef = firestore.collection("TVideo").orderBy("created","desc").limit(10);
        if(params.after)
        {
          let doc = await firestore.collection("TVideo").doc(params.after).get();
          docRef = docRef.startAfter(doc);
        }

          let data = await docRef.get();

        let results = [];
        if(data.size>0)
        {
          data.forEach((doc)=>{
            let item = doc.data();
            item.id = doc.id;
            results.push(item);
          })
        }

    return results;
}
export async function UpdateTVideo(data){
  let relData = 0;
  const params = data;
  if (params.id) {

      let newDoc = _.cloneDeep(params);
      let docRef = firestore.collection("TVideo").doc(params.id);
      await docRef.set({...newDoc},{merge:true});
      relData = docRef.id;
  }
  if(relData!=0)
  {
    let result = await CommonHelper.postFormData(CREATE_FOLDER,{folder:relData});
    console.log(result);
  }
  return relData;
}
export async function DeleteTVideo(id){
  let doc = await firestore.collection("TVideo").doc(id).get();
  if(doc.exists)
  {


    await firestore.collection("TVideo").doc(id).delete();
  }

    return id;
}
export async function GetAllFiles(user,params={}){
  console.log(user,params)
      let docRef = firestore.collection(collection).doc(user).collection("files").orderBy("created","desc").limit(10);
        if(params.after)
        {
          let doc = await firestore.collection(collection).doc(user).collection("files").doc(params.after).get();
          docRef = docRef.startAfter(doc);
        }

          let data = await docRef.get();

        let results = [];
        if(data.size>0)
        {
          data.forEach((doc)=>{
            let item = doc.data();
            item.id = doc.id;
            results.push(item);
          })
        }
    return results;
}
export async function GetAllEFiles(user,params={}){

      let docRef = firestore.collection(collection).doc(user).collection("efiles").orderBy("created","desc").limit(10);
        if(params.after)
        {
          let doc = await firestore.collection(collection).doc(user).collection("efiles").doc(params.after).get();
          docRef = docRef.startAfter(doc);
        }

          let data = await docRef.get();

        let results = [];
        if(data.size>0)
        {
          data.forEach((doc)=>{
            let item = doc.data();
            item.id = doc.id;
            results.push(item);
          })
        }
    return results;
}
export async function DeleteFile(user,file){
      await firestore.collection(collection).doc(user).collection("files").doc(file).delete();
      await firestore.collection(collection).doc(user).update({files:firebase.firestore.FieldValue.increment(-1)});
    return 1;
}
export async function addEditedFile(user,file){
      await firestore.collection(collection).doc(user).collection("efiles").doc(file.name).set(file);
      await firestore.collection(collection).doc(user).update({efiles:firebase.firestore.FieldValue.increment(1)});
    return 1;
}
export async function DeleteEditedFile(user,file){
      await firestore.collection(collection).doc(user).collection("efiles").doc(file).delete();
      await firestore.collection(collection).doc(user).update({efiles:firebase.firestore.FieldValue.increment(-1)});
    return 1;
}
export async function DeleteAllFiles(user){
  let doc = await firestore.collection(collection).doc(user).get();
  if(doc.exists)
  {
    let data = await firestore.collection(collection).doc(user).collection("files").get();
    let count = data.size;
    if(data.size>0)
    {

      var batch = firestore.batch();
      data.forEach(doc=>{
        let i = doc.data();
        i.id = doc.id;
          let ref = firestore.collection(collection).doc(user).collection("files").doc(i.id);
          batch.delete(ref);
      })
      await batch.commit();
    }
     data = await firestore.collection(collection).doc(user).collection("efiles").get();
     count = data.size;
    if(data.size>0)
    {

      var batch = firestore.batch();
      data.forEach(doc=>{
        let i = doc.data();
        i.id = doc.id;
          let ref = firestore.collection(collection).doc(user).collection("efiles").doc(i.id);
          batch.delete(ref);
      })
      await batch.commit();
    }
    await firestore.collection(collection).doc(user).update({files:0});
    await firestore.collection(collection).doc(user).update({efiles:0});
  }

    return user;
}
export async function DeleteUser(user){
  let doc = await firestore.collection(collection).doc(user).get();
  if(doc.exists)
  {
    let data = await firestore.collection(collection).doc(user).collection("files").get();
    let count = data.size;
    if(data.size>0)
    {

      var batch = firestore.batch();
      data.forEach(doc=>{
        let i = doc.data();
        i.id = doc.id;
          let ref = firestore.collection(collection).doc(user).collection("files").doc(i.id);
          batch.delete(ref);
      })
      await batch.commit();
    }
    await firestore.collection(collection).doc(user).update({files:firebase.firestore.FieldValue.increment(count*-1)});
    await firestore.collection(collection).doc(user).delete();
  }

    return user;
}

export async function Add(data){
  let relData = 0;
  const params = data;
  if (params.name && params.truckNumber&& params.password) {
    let docRef = firestore.collection(collection).doc();
    let id = docRef.id;
    docRef = firestore.collection(collection).doc(id);
    let search = generateSearch(params);
    await docRef.set({...params,id,search,created:(new Date().getTime()),updated:(new Date().getTime())});
    relData = id;
  }
  // if(relData!=0)
  // {
  //   let result = await CommonHelper.postFormData(CREATE_FOLDER,{folder:relData});
  //   console.log(result);
  // }
    return relData;
}
export async function Update(data){
  let relData = 0;
  const params = data;
  if (params.id) {
    let doc = await firestore.collection(collection).doc(params.id).get();
    if(doc.exists)
    {
      let newDoc = _.cloneDeep(params);
      delete newDoc['created'];
      let search = generateSearch(newDoc);
      let docRef = firestore.collection(collection).doc(params.id);
      await docRef.update({...newDoc,search,updated:(new Date().getTime())});
      relData = docRef.id;
    }
  }
  // if(relData!=0)
  // {
  //   let result = await CommonHelper.postFormData(CREATE_FOLDER,{folder:relData});
  //   console.log(result);
  // }
  return relData;
}
export async function Delete(id){
    let result = await CommonHelper.postFormData(DELETE_API,{id});
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function GetById(id){
    let result = await CommonHelper.postFormData(GETBYID_API,{id});
    let rel = null;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function CheckTruckName(name,id){
  let relData = false;
  let docRef = firestore.collection(collection).where("name",'==',name.toUpperCase());
  if(id)
  {
    docRef = docRef.where("id",'!=',id);
  }
  let data = await docRef.get();
  if(data.size>0)
  {
    relData = true;
  }
  else {
    relData = false;
  }

    return relData;
}
export async function Search(search){
    let result = await CommonHelper.postFormData(SEARCH_API,{search});
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}
function generateSearch(item){
  let arr = CommonHelper.slugify(item.name).split('-');
  arr.push(CommonHelper.slugify(item.name).replace(/\-/gi, " "));
  return arr;
}
