import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { JForm } from "components";
import { AdminHelper, CommonHelper } from "helpers";
import lodash, { set } from "lodash";
import { saveAs } from "file-saver";
import UploadComp from "./Upload";
import WebViewer from "@pdftron/webviewer";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress,
  Divider,
  Modal,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DataTable, DataToolbar } from "components";
import CityList from "assets/json/tinh.json";
import { ProductList } from "views/Admin";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  link: {
    cursor: "pointer",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    flexBasis: 500,
  },
  note: {
    whiteSpace: "pre-wrap",
  },
}));

const Edit = (props) => {
  const viewer = React.useRef(null);
  const { item, onCancel, onSuccess, cats } = props;
  const history = useHistory();
  const confirm = CommonHelper.useConfirm();
  const classes = useStyles();
  const [state, setState] = useState({ myForm: {} });
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [url, setUrl] = useState("");
  const [pdfInstance, setPdfInstance] = useState(null);

  React.useEffect(() => {
    getItemUri(item);
    //loadCounter();
  }, []);

  const getItemUri = async (item) => {
    let uri = "";
    if (item.document) uri = item.document;
    let docurl = await AdminHelper.DocApi.getFileDownloadUrl(uri);
    setUrl(docurl);
    WebViewer(
      {
        isAdminUser : true,
        path: "/webviewer",
        licenseKey:
          "demo:1684734427876:7da151660300000000cff786e19b3a680a64efe0971304c1549d399ecb",
        initialDoc: true
          ? docurl
          : "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
      },
      viewer.current
    ).then((instance) => {
      instance.UI.disableReplyForAnnotations((annot) => true)
      instance.UI.enableFeatures([instance.UI.Feature.ContentEdit]);
      setPdfInstance(instance);
      const { documentViewer, annotationManager } = instance.Core;
      //annotationManager.setIsAdminUser(true);
    });
  };

  const saveUpdate = async () => {
    setLoading(true);
    const { documentViewer, annotationManager } = pdfInstance.Core;
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    //   const url = URL.createObjectURL(blob);
    // window.open(url);
    await AdminHelper.DocApi.updateFile(blob, item.dockerID + ".pdf");
  };

  const sendEmailConfirm = async (item) => {
    confirm({
      title: "Alert",
      description: `Do you want to send delivery document to ${item.email}?`,
      confirmText: "Send now",
      cancelText: "Cancel",
    })
      .then(() => {
        sendEmail(item);
        //console.log('ok');
      })
      .catch(() => {
        //console.log('cancel');
      });
  };

  const sendEmail = async (item) => {
    //get document link
    let uri = item.document;
    let url = await AdminHelper.DocApi.getFileDownloadUrl(uri);
    let data = {
      email: item.email,
      from: "noreply@jjradmin.au",
      from_name: "JJR",
      subject: "Delivery Document - " + item.dockerID,
      message: `Dear customer,<br/><br/>Please find the delivery document for your job ${item.dockerID}.<br/><br/><a href='${url}'>Your Document</a><br/><br/>Regards,<br/>JJR`,
    };
    let rel = await AdminHelper.JobApi.sendEmail(data);
    console.log(rel);
    if (rel && rel == "success") {
      CommonHelper.NotificationManager.success("Success");
    } else {
      CommonHelper.NotificationManager.error("Cant send email");
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={() => onCancel()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div style={{ width: "100%", maxWidth: "800px" }}>
                <Typography
                  className={classes.title}
                  variant="h2"
                  align="center"
                >
                  {item.name} - {item.dockerID}
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      saveUpdate();
                    }}
                  >
                    Save Update
                  </Button>
                  {(item.status == "finished" || item.status == "archived") && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        sendEmailConfirm(item);
                      }}
                    >
                      Send to Customer
                    </Button>
                  )}
                </div>

                <div
                  className="webviewer"
                  ref={viewer}
                  style={{ height: "100vh" }}
                ></div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object,
};

export default Edit;
