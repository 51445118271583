import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import _ from 'lodash';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  }
}));
const defaultItem = {
  id:0,
  name:"",
  slug:"",
  parent:"0"
}

const Category = () => {
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const theme = useTheme();
  React.useEffect(()=>{
    AdminHelper.getAdminStore().dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:false });
    CommonHelper.deleteLocalData('ADMINACCOUNT');
    CommonHelper.deleteLocalData('ADMINTOKEN');
    history.replace(AdminHelper.getAdminPath()+'/login');
  },[])
  if(!AdminHelper.checkAdminAccount())
  {
    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }





  return (
    <div className={classes.root}>

    </div>
  );
};

export default Category;
