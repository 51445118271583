import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm,UploadImage} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import lodash from 'lodash';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },

  signInButton: {
    margin: theme.spacing(2, 0)
  },content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
  ,
  form:{
    flexBasis: 500,
  }
}));

const Edit = props => {
  const {item,onCancel,onSuccess,cats,user,category} = props;
  const history  = useHistory();

  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);
  const [Images, setImages] = useState(null);
  const generateCat = () => {
    let arr = [];

    for (const value of cats)
    {
      arr.push({label:value.name,value:value.id});
    }
    return arr;
  }
  const fetchUserData = async (search) =>{
    let data = await AdminHelper.UserApi.Search(search);
    return data;
  }
  const fields = {
    category: {
      type:'select',
      label:'Danh mục',
      data:generateCat(),
      required:true,
      value:category?category.id:item.category,
      validate:{
        presence: true
      }
    },


    identify: {
      type:'text',
      label:'Số xe/Số imei/Số khung',
      value:item.identify,
      required:true,
      validate:{
        presence: true,
        length: {
          minimum: 6
        }
      }
    },

    note: {
      multiline:12,
      type:'text',
      label:'Ghi Chú',
      value:item.note,
      required:false,
      validate:{

      }
    },name: {
      type:'text',
      label:'Tên',
      value:item.name,

    },
        id_user: {
          type:'autocomplete',
          label:'Khách',
          required:true,
          data:[],
          value:user?user:item.id_user,

          fetchData:fetchUserData

        }
  }

  const handleFormChange = myForm => {
  //  console.log(myForm.values);
    setState(state=>({...state,myForm}));
  }

  const confirmClick = event => {
      event.preventDefault();
      updateData();
  }

  const updateData = async () =>{
    await setLoading(true);
    let newItem = {...item,...state.myForm.values};
    if(newItem.id_user)
    newItem.id_user=newItem.id_user.id;
    if(Images)
    {
      if(Images.DeletedImages)
      {
        let arr = [];
        for(let item of Images.DeletedImages)
        {
          let filename = item.replace(/^.*[\\\/]/, '');
          filename = filename.replace('?alt=media', '');
          filename = filename.replace(/%2F/gi, '/');
          arr.push(filename);
        }
        Images.DeletedImages = arr;
      }
    }
    newItem.imageFile = JSON.stringify(Images);

    let id = 0;
    if(newItem.id == '0')
    {
      id = await AdminHelper.ProductApi.Add(newItem);
      if(id!=0)
      return onSuccess();
    }
    else {
      id = await AdminHelper.ProductApi.Update(newItem);
      if(id!=0)
      return onSuccess();
    }
    await setLoading(false);
  }

  const onFileChanged = (NewImages,DeletedImages) => {
    setImages({NewImages,DeletedImages});
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={()=>onCancel()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  {item.id=='0'?'Thêm mới':'Sửa'}
                </Typography>
                <JForm fields={fields} handleFormChange={handleFormChange}  />
                <UploadImage onChange={onFileChanged} Items={item.images} />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!state.myForm.isValid}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={confirmClick}
                  >
                    {loading?<CircularProgress color="secondary" />:item.id=='0'?'Thêm':'Cập nhật'}
                  </Button>
              </form>
              <img src="" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object
};

export default Edit;
