import * as CommonHelper from 'helpers/Common';
const GETALL_API = CommonHelper.ADMIN_SERVER+'Product-GetAll';
const ADD_API = CommonHelper.ADMIN_SERVER+'Product-Add';
const UPDATE_API = CommonHelper.ADMIN_SERVER+'Product-Update';
const DELETE_API = CommonHelper.ADMIN_SERVER+'Product-Delete';
const GETBYUSER_API = CommonHelper.ADMIN_SERVER+'Product-GetByUser';
const SEARCH_API = CommonHelper.ADMIN_SERVER+'Product-Search';
export async function GetAll(params){
    let result = await CommonHelper.postFormData(GETALL_API,{...params});
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}

export async function Add(data){
    let result = await CommonHelper.postFormData(ADD_API,data);
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function Update(data){
    let result = await CommonHelper.postFormData(UPDATE_API,data);
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function Delete(id){
    let result = await CommonHelper.postFormData(DELETE_API,{id});
    let rel = 0;
    if(result&&result.status==1)
    {
      rel = result.data;
    }
    return rel;
}
export async function GetByUser(id){
    let result = await CommonHelper.postFormData(GETBYUSER_API,{id});
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}
export async function Search(search){
    let result = await CommonHelper.postFormData(SEARCH_API,{search});
    let items = [];
    if(result&&result.status==1)
    {
      items = result.data;
    }
    return items;
}
