import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {JForm} from 'components';
import {AdminHelper,CommonHelper} from 'helpers';
import lodash from 'lodash';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CityList from 'assets/json/tinh.json';
import {ProductList} from 'views/Admin';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  grid: {
    height: '100%'
  },

  signInButton: {
    margin: theme.spacing(2, 0)
  },content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }, contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }
  ,
  form:{
    flexBasis: 500,
  }
}));

const Edit = props => {
  const {item,onCancel,onSuccess,cats} = props;
  const history  = useHistory();

  const classes = useStyles();
  const [state, setState] = useState({myForm:{}});
  const [loading, setLoading] = useState(false);
  const generateCity = () => {
    let arr = [];

    for (const [key, value] of Object.entries(CityList))
    {
      arr.push({label:value.name,value:key})
    }
    return arr;
  }

  const fields = {
    name: {
      type:'text',
      label:'Name',
      value:item.name,
      required:true,
      validate:{
        presence: true,
        length: {
          minimum: 1
        }
      }
    },
    password: {
      type:'text',
      label:'Password',
      value:item.password,
      required:true,
      validate:{
        presence: true,
      }
    },
    note: {
      multiline:4,
      type:'text',
      label:'Note',
      value:item.note,
      required:false,
      validate:{

      }
    },
  }

  const handleFormChange = myForm => {
    setState(state=>({...state,myForm}));
  }

  const confirmClick = event => {
      event.preventDefault();
      updateData();
  }



  const updateData = async () =>{
    await setLoading(true);
    let newItem = {...item,...state.myForm.values};
    let id = 0;
    if(newItem.name)
    {
      newItem.name = newItem.name.toUpperCase();
    }
    
    let check = await AdminHelper.UserApi.CheckTruckName(newItem.name,newItem.id);
     
      if(check)
      {
        await setLoading(false);
        CommonHelper.NotificationManager.error('Truck name existed');
        return;
      }
    if(newItem.id == 0)
    {
      
      id = await AdminHelper.UserApi.Add(newItem);
      console.log(id)
      if(id!=0)
      return onSuccess();
    }
    else {
      id = await AdminHelper.UserApi.Update(newItem);
      if(id!=0)
      return onSuccess();
    }
    await setLoading(false);
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={()=>onCancel()}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <div style={{width:'100%',maxWidth:'800px'}}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  {item.id=='0'?'Add New':'Edit'}
                </Typography>
                <JForm fields={fields} handleFormChange={handleFormChange}  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!state.myForm.isValid}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={confirmClick}
                  >
                    {loading?<CircularProgress color="secondary" />:item.id=='0'?'Add':'Update'}
                  </Button>
              </div>
            </div>
          </div>
        </Grid>
        {false?<Grid
          item
          xs={12}
        >
        <ProductList user={item} />
      </Grid>:false}
      </Grid>
    </div>
  );
};

Edit.propTypes = {
  history: PropTypes.object
};

export default Edit;
