import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid,Container } from '@material-ui/core';
import {SiteHelper,AdminHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import {
  TotalUsers,
} from './components';
import _ from 'lodash';
//import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import {CategoryList} from 'views/Site/components';
const useStyles = makeStyles(theme => ({
  root: {

  }
}));
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
mobile: {
  breakpoint: { max: 464, min: 0 },
  items: 1
}

};
const Dashboard = () => {
  const classes = useStyles();
  let location = useLocation();
  const [cats, setCats] = React.useState([]);
  React.useEffect(()=>{
    loadCats();
  },[])
  const loadCats = async () =>{
    let data = await AdminHelper.CategoryApi.GetAll();
    data = _.sortBy(data,['name'],['asc']);
    setCats(data);
  }
  const getRootCats = () => {
    let arr = AdminHelper.nestedArray(_.cloneDeep(cats));
    return arr;
  }
  return (
    <div className={classes.root}>

        <Carousel responsive={responsive} showDots={true}  arrows={false} autoPlay infinite autoPlaySpeed={5000} draggable={false}>
              <div style={{backgroundColor:'#fe4114',textAlign:'center'}}>
                  <Container maxWidth='lg'><img  src="https://cf.shopee.vn/file/6d3fc3590e811f2e6533ff52bca0d4bd_xxhdpi" style={{width:'100%'}} /></Container>
              </div>
              <div style={{backgroundColor:'#08419e',textAlign:'center'}}>

                  <Container maxWidth='lg'><img src="https://cf.shopee.vn/file/c4379ec036c43abd8a002bbcf3674f5a_xxhdpi" style={{width:'100%'}} /></Container>

              </div>
          </Carousel>

          <Container maxWidth="lg" >
            <CategoryList items={getRootCats()} />
          </Container>
    </div>
  );
};

export default Dashboard;
