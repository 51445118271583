import React from 'react';
import { makeStyles,useTheme } from '@material-ui/styles';
import { Grid,Typography } from '@material-ui/core';
import {AdminHelper,CommonHelper} from 'helpers';
import {
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import {DataTable,DataToolbar} from 'components';
import {EditComp} from './components';
import _ from 'lodash';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(1)
  },
  link: {
    cursor:'pointer',fontWeight:'bold',color:theme.palette.primary.main
  }
}));
const defaultItem = {
  id:0,
  name:"",
  slug:"",
  parent:"0"
}

const Category = () => {
  const confirm = CommonHelper.useConfirm();
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme();
  const [search, setSearch] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [showEdit, setShowEdit] = React.useState(null);


  const dataValues = [
    {label:'Tên',value:'subName',className:classes.link,action:'edit'},
    {label:'SP',value:'Product'},
    {label:'Cập nhật',width:150,value:'updated',align:'right',dateFormat:'DD/MM/YYYY HH:mm:ss'}];
  const dataActions = [{name:'Sửa',type:'edit'},{name:'Xoá',type:'delete',color:'red'}];
  const toolbarActions = [{name:'Thêm mới',type:'add'}];

  React.useEffect(()=>{
    loadItems();
  },[])
  const loadItems = async () =>{
    let data = await AdminHelper.CategoryApi.GetAll();
    data = _.sortBy(data,['name'],['asc']);
    setItems(data);
  }
  const onSearchChange = event => {
    setSearch(event.target.value);
  }
  if(!AdminHelper.checkAdminAccount())
  {
    return (<Redirect
            to={{
              pathname: AdminHelper.getAdminPath()+'/login',
              state: { from: location }
            }}
          />)
  }
  const pushChilds = (level,arr,results,id=0) => {
     arr = _.cloneDeep(arr);
    for(let item of arr)
    {
      if(id!=0&&id==item.id)
      {
        continue;
      }
      item.name = item.name;
      item.subName = " — ".repeat(level)+item.name;
      results.push(item);
      if(item.childs)
      {
        pushChilds(level+1,item.childs,results,id);
      }
    }
  }
  const dataTable = (id=0) => {
    let rel = [];
    let arr = AdminHelper.nestedArray(_.cloneDeep(items));
    pushChilds(0,arr,rel,id);
    return rel;
  }
  const handleActions = (type,item) => {
    console.log(type,item);
    if(type=='edit')
    {
      setShowEdit(_.cloneDeep(item));
    }
    if(type=='delete')
    {
      deleteItem(item)
    }
  }
  const handleToolbarActions = (type) => {
    console.log(type);
    if(type=='add')
    {
      setShowEdit(_.cloneDeep(defaultItem));
    }
  }
  const confirmDelete = async (item) => {
    let id = await AdminHelper.CategoryApi.Delete(item.id);
    if(id!=0)
    {
      CommonHelper.NotificationManager.success('Đã xoá thành công');
      setTimeout(loadItems,500);
    }
    else
    {
      CommonHelper.NotificationManager.error('Hãy thử lại');
    }
  }
  const deleteItem = async (item) => {
    if(item.Product && item.Product != 0)
    {
      CommonHelper.NotificationManager.error('Không thể xoá danh mục vì còn sản phẩm trong danh mục này.');
      return;
    }
    confirm({ title:'Thông Báo',description: 'Bạn có muốn xoá?',confirmText:'Đồng ý',cancelText:'Trở lại' })
      .then( () => {
        confirmDelete(item);
        //console.log('ok');
      }).catch(()=>{
        //console.log('cancel');
      });
  }
  const handleSuccess = () => {
    setShowEdit(false);
    setTimeout(loadItems,500);
  }

  return (
    <div className={classes.root}>
      {!showEdit?<DataToolbar title="Danh mục" actions={toolbarActions} onActionCallback={handleToolbarActions} onSearchChange={onSearchChange} />:false}
      <div className={classes.content}>
        {showEdit?<EditComp onSuccess={handleSuccess} cats={dataTable(showEdit.id)} onCancel={()=>setShowEdit(null)} item={showEdit} />:false}
        {!showEdit?<DataTable handleActions={handleActions} dataActions={dataActions} dataValues={dataValues} data={dataTable()} rows={100} />:false}
      </div>
    </div>
  );
};

export default Category;
